import React from 'react';
import {BasePageData, BasePageFooter, BasePageHeader} from '../helpers/base-page';
import {graphql} from 'gatsby';
import './styles.scss';
import {TitleSection} from '../components/usecases/title-section';
import {BackgroundFigure} from '../components/shared/background-figure';
import {MarkdownRemark} from '../models/markdown-remark';
import {RegularSectionModel} from '../models/regular-section-model';
import {UsecaseSection} from '../components/usecases/usecase-section';
import { RegularSection } from '../components/shared/regular-section';

interface UseCasePageData extends BasePageData {
  pageData: MarkdownRemark<{
    seoTitle: string;
    title: string;
    heroImage: string;
    altText: string;
    subText: string;
    subSections: Array<{
      type: 'useCases';
      searchPlaceHolder: string;
      useCasesItems: Array<{
        smallImage: string;
        color: string;
        altText: string;
        useCase: string;
      }>
    } | RegularSectionModel>
  }>;
}

const UseCasePage = ({data}: {data: UseCasePageData}): JSX.Element => {

  return (
    <React.Fragment>
      <BasePageHeader
        siteTitle={data.pageData.edges[0].node.frontmatter.seoTitle ?? data.metaInfo.edges[0].node.frontmatter.siteTitle}
        siteDescription={data.metaInfo.edges[0].node.frontmatter.siteDescription}
        siteDescriptionTitle={data.metaInfo.edges[0].node.frontmatter.siteDescriptionTitle}
        siteImage={data.metaInfo.edges[0].node.frontmatter.siteImage}
        siteUrl={data.metaInfo.edges[0].node.frontmatter.siteUrl}
        logoLight={data.logos.edges[0].node.frontmatter.logoHeaderLight}
        logoDark={data.logos.edges[0].node.frontmatter.logoHeaderDark}
        logoShort={data.logos.edges[0].node.frontmatter.logoShort}
        links={data.navigation.edges[0].node.frontmatter.topCenterNavigation}
        modules={data.modules.edges.map(m => m.node.frontmatter)}
        usecases={data.usecases.edges.map(u => u.node.frontmatter)}
        hubspotFormId={data.hubspot.edges[0].node.frontmatter.hubSpotFormId}
        hubspotPortalId={data.hubspot.edges[0].node.frontmatter.hubSpotPortalId}
        contactFormConfig={data.contactFormConfig.edges[0].node.frontmatter}
      />
      <TitleSection
        title={data.pageData.edges[0].node.frontmatter.title}
        text={data.pageData.edges[0].node.frontmatter.subText}
        image={data.pageData.edges[0].node.frontmatter.heroImage}
        alt={data.pageData.edges[0].node.frontmatter.altText}
      />
      {
        data.pageData.edges[0].node.frontmatter.subSections.map((section, index) => {
          switch (section.type) {
            case 'useCases': {
              return (
                <UsecaseSection
                  searchPlaceholder={section.searchPlaceHolder}
                  usecases={section.useCasesItems.map(i => ({
                    info: {
                      img: i.smallImage,
                      color: i.color,
                      alt: i.altText
                    },
                    origin: data.usecases.edges.map(u => u.node.frontmatter).find(u => u.title === i.useCase)
                  }))}
                />
              );
            }
            case 'regular': {
              return (
                <RegularSection key={index} {...section.section} />
              );
            }
            default:
              return null;
          }
        })
      }
      <div className={'desktop-only'}>
        <BackgroundFigure
          type={'strips'}
          color={'#ffc02a'}
          size={{width: 76, height: 203}}
          position={{left: 0, top: 890}}
        />
        <BackgroundFigure
          type={'circle'}
          color={'#ffc02a'}
          size={{width: 23, height: 46}}
          position={{right: 0, top: 745}}
          style={{
            zIndex: 0,
            borderRadius: 23,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        />
        <div style={{
          position: 'absolute',
          right: 0,
          top: 1500,
          zIndex: 0,
          width: 36,
          height: 96,
          overflow: 'hidden'
        }}>
          <BackgroundFigure
            type={'triangle'}
            color={'#eaedfd'}
            size={{width: 60, height: 60}}
            position={{left: 30}}
          />
        </div>
      </div>
      <BasePageFooter
        feedbackTitle={data.contact.edges[0].node.frontmatter.title}
        feedbackLabel={data.contact.edges[0].node.frontmatter.subText}
        feedbackButtonText={data.contact.edges[0].node.frontmatter.buttonText}
        feedbackInputPlaceholder={data.contact.edges[0].node.frontmatter.inputPlaceholder}
        navigation={data.navigation}
        integrations={data.integrations}
        blogArticles={data.blogArticles}
        blogCategories={data.blogCategories}
        features={data.features.edges.map(f => f.node.frontmatter)}
        footerLogo={data.logos.edges[0].node.frontmatter.logoFooter}
      />
    </React.Fragment>
  );
};

export default UseCasePage;

export const pageQuery = graphql`
  {
    ...BaseData
    pageData: allMarkdownRemark(filter: {
      fileAbsolutePath: {
        regex: "/useCasesPage.md$/"
      }
    }) {
      edges {
        node {
          frontmatter {
            seoTitle
            title
            heroImage
            altText
            subText
            subSections {
              type
              section {
                alignment
                label
                color
                background
                title
                image
                altText
                subText
              }
              searchPlaceHolder
              useCasesItems {
                smallImage
                color
                altText
                useCase
              }
            }
          }
        }
      }
    }
  }
`;
