import React, {useState} from 'react';
import {UsecaseModel} from '../../models/usecase';
import styled from 'styled-components';
import SearchIcon from '../../images/search-blue.svg';
import {device} from '../../models/devices';

const Container = styled.section`
  margin: 0 auto 120px;
  max-width: 1170px;
  
  @media screen and ${device.tablet} {
    padding: 0 24px;
    margin-bottom: 80px;
  }
`;

const Item = styled.a<{color: string;}>`
  position: relative;
  background-color: ${props => props.color};
  border-radius: 8px;
  text-align: center;
  color: white;
  padding: 40px;
  box-sizing: border-box;
  text-decoration: none;
  overflow: hidden;

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: transform 0.3s ease-out;
    transform-origin: bottom center;
  }

  &:hover {
    img {
      transform: scale(1.2);
    }
  ;
  }

  @media screen and ${device.tablet} {
    padding: 28px 25px 0;
  }
`;

const Header = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 13px;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 500px) {
    font-size: 16px;
    line-height: 1.5em;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 500;
  position: relative;
  z-index: 2;

  @media screen and (max-width: 500px) {
    font-size: 12px;
    line-height: 1.5em;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 1fr;

  &:before {
    content: '';
    width: 0;
    padding-bottom: 100%;
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }
  
  & > *:first-child {
    grid-row: 1 / 1;
    grid-column: 1 / 1;
  }

  @media screen and (max-width: 800px) {
    grid-gap: 24px;
    grid-template-columns: 1fr;
  }
`;

const InputWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto 120px;
  position: relative;
  border: 1px solid #00e0dd;
  box-shadow: 10px 12px 14px rgba(233, 233, 233, 0.230551);

  &:before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 100%;
    background: #00e0dd;
    position: absolute;
  }

  input {
    background: white;
    border: none;
    //border-radius: 10px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #54595A;
    letter-spacing: 0.9px;
    padding: 16px 50px 16px 31px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
  }

  img {
    position: absolute;
    right: 24px;
    top: 18px;
  }
  
  @media screen and ${device.tablet} {
    margin-bottom: 80px;
    
    input {
      font-size: 12px;
      padding-left: 16px;
    }
  }
`;

export const UsecaseSection = (props: {
  searchPlaceholder: string;
  usecases: Array<{
    info: {
      color: string;
      img: string;
      alt: string;
    }
    origin?: UsecaseModel;
  }>;
}): JSX.Element => {

  const [query, setQuery] = useState('');

  const filteredUsecases = props
    .usecases
    .filter(u =>
      u.origin?.title.toLowerCase().includes(query.toLowerCase()) ||
      u.origin?.description.toLowerCase().includes(query.toLowerCase())
    );

  return (
    <Container>
      <InputWrapper>
        <input
          type="text"
          placeholder={props.searchPlaceholder}
          onChange={e => setQuery(e.target.value)}
        />
        <img src={SearchIcon} alt=""/>
      </InputWrapper>

      <Grid>
        {
          filteredUsecases.map((usecase, index) => (
            <Item href={usecase.origin?.link} key={index} color={usecase.info.color}>
              <img src={usecase.info.img} alt={usecase.info.alt}/>
              <Header>{usecase.origin?.title}</Header>
              <Text>{usecase.origin?.description}</Text>
            </Item>
          ))
        }
      </Grid>
    </Container>
  );
};
