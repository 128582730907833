import React from 'react';
import Background from '../../images/impact-background.png';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import {device} from '../../models/devices';

const Wrapper = styled.div`
  max-width: 1170px;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 470px auto;
  grid-template-rows: auto auto auto 1fr;
  grid-column-gap: 130px;
  grid-row-gap: 24px;
  grid-template-areas:
    "header image"
    "text image"
    "space image";
  padding: 190px 0 133px;
  margin: 0 auto 120px;

  @media screen and ${device.tablet} {
    padding: 115px 24px 76px;
    grid-template-areas: "header" "text" "image";
    grid-template-columns: 1fr;
    margin-bottom: 112px;
  }
`;

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
  line-height: 1.33;
  color: white;
  grid-area: header;
  margin: 0;

  @media screen and ${device.tablet} {
    font-size: 32px;
    line-height: 1.5em;
  }
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 500;
  line-height: 1.78;
  color: #cfe7fc;
  letter-spacing: -0.1px;
  grid-area: text;
`;

const Image = styled.img`
  max-width: 571px;
  width: 100%;
  grid-area: image;
`;

export const TitleSection = (props: {
  title: string;
  text: string;
  image: string;
  alt: string;
}): JSX.Element => {
  return (
    <section style={{
      background: `url(${Background}) no-repeat bottom center`,
      backgroundSize: 'cover'
    }}>
      <Wrapper>
        <Header><ReactMarkdown>{props.title}</ReactMarkdown></Header>
        <Text><ReactMarkdown>{props.text}</ReactMarkdown></Text>
        <Image src={props.image} alt={props.alt}/>
      </Wrapper>
    </section>
  );
};
